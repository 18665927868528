module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jaen App","short_name":"Jaen","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"src/favicon.ico","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4fbcff86328847d875d31e3c8409a890"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":[],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"sampleRate":1,"enableTracing":true,"debug":true,"tracesSampleRate":1,"replaysSessionSampleRate":1,"replaysOnErrorSampleRate":1,"dsn":"https://9c5258c9df2e385800a582e94f6068ea@sentry.cronit.io/13"},
    },{
      plugin: require('../node_modules/gatsby-plugin-jaen/gatsby-browser.tsx'),
      options: {"plugins":[],"pylonUrl":"https://pylons.cronit.io/jaen/graphql","remote":{"repository":"agt-guntrade/shop"},"zitadel":{"organizationId":"277049780788535300","clientId":"277049969163182084@shop","authority":"https://accounts2.cronit.io","redirectUri":"https://ried.agt-guntrade.at","projectIds":["270250070505431044"]},"sentry":{"org":"cronit","project":"agt-guntrade-shop","dsn":"https://9c5258c9df2e385800a582e94f6068ea@sentry.cronit.io/13"}},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
