import {chakra} from '@chakra-ui/react'
import React from 'react'

const Logo: React.FC<{
  mode?: 'light' | 'dark'
}> = ({mode = 'dark'}) => {
  return (
    <chakra.svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="100%"
      height="100%"
      version="1.1"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        fillRule: 'evenodd',
        clipRule: 'evenodd'
      }}
      viewBox="0 0 46000 15000"
      fill={mode === 'light' ? '#FFF' : '#2B2A29'}
      color={mode === 'light' ? '#FFF' : '#2B2A29'}
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <defs>
        <style type="text/css">
          {`
    .fil4 {fill:#FEFEFE}

    .fil1 {fill:#E31E24;fill-rule:nonzero}
    `}
        </style>
      </defs>
      <g id="Ebene_x0020_1" fill="none">
        <metadata id="CorelCorpID_0Corel-Layer" />
        <g id="_2422033233024">
          <g>
            <path
              className="fil1"
              d="M5424 9993l1445 -2002 3221 0 -2001 -2772 -3479 4774 -2965 0 4945 -6861c157,-229 375,-428 653,-600 307,-185 603,-278 889,-278 292,0 588,93 888,278 264,164 478,364 642,600l4827 6861 -9065 0z"
            />
            <path
              fill="currentColor"
              d="M23897 2275l-1541 2002 -3874 0c-657,0 -1201,177 -1633,530 -431,353 -647,804 -647,1354 0,549 214,993 642,1332 428,339 974,509 1638,509l2782 0 0 -717 -2997 0 -1573 -1906 6968 0 0 4614 -5180 0c-1342,0 -2453,-373 -3335,-1119 -881,-746 -1321,-1682 -1321,-2810 0,-1127 435,-2040 1305,-2740 871,-699 1988,-1049 3351,-1049l5415 0z"
            />
            <polygon
              fill="currentColor"
              points="33338,2275 31786,4277 29474,4277 29474,9993 27077,9993 27077,4277 23898,4277 25439,2275 "
            />
            <path
              fill="currentColor"
              d="M39109 2254c1448,0 2760,587 3709,1537 949,949 1537,2260 1537,3709 0,1449 -588,2760 -1537,3709 -949,950 -2261,1537 -3709,1537 -1449,0 -2760,-587 -3710,-1537 -949,-949 -1536,-2260 -1536,-3709 0,-1449 587,-2760 1536,-3709 950,-950 2261,-1537 3710,-1537zm-19 6930l0 -1670 -1645 0 0 86 -3153 0c27,1291 560,2457 1410,3306 850,850 2016,1384 3307,1410l0 -3132 81 0zm0 -1698l0 -4803c-1323,5 -2520,543 -3388,1411 -850,849 -1383,2015 -1410,3306l3153 0 0 86 1645 0zm38 -4803l0 4803 1644 0 0 -86 3153 0c-26,-1291 -560,-2457 -1410,-3306 -868,-868 -2065,-1406 -3387,-1411zm0 4831l0 1670 81 0 0 3132c1290,-26 2456,-560 3306,-1410 850,-849 1384,-2015 1410,-3306l-3153 0 0 -86 -1644 0z"
            />
            <path
              fill="currentColor"
              d="M15620 11362l-275 356 -690 0c-117,0 -214,32 -291,95 -77,62 -115,143 -115,241 0,98 38,177 114,237 77,61 174,91 292,91l496 0 0 -128 -534 0 -280 -339 1241 0 0 821 -923 0c-239,0 -437,-66 -594,-199 -157,-133 -235,-300 -235,-500 0,-201 77,-364 232,-489 155,-124 354,-186 597,-186l965 0zm1920 749c0,417 -286,625 -858,625l-89 0c-572,0 -858,-208 -858,-625l0 -749 425 0 0 749c0,125 54,205 164,240 58,21 178,31 358,31 144,0 246,-16 307,-46 83,-39 124,-114 124,-225l0 -749 427 0 0 749zm1833 448c0,125 -57,187 -170,187 -81,0 -159,-31 -232,-92l-805 -673 0 755 -427 0 0 -1197c0,-51 16,-93 48,-126 33,-33 79,-50 139,-50 77,0 149,28 215,82l805 674 0 -757 427 0 0 1197zm2550 -1197l-277 356 -412 0 0 1018 -427 0 0 -1018 -566 0 274 -356 1408 0zm1642 1374l-620 0 -416 -345 -587 0 0 345 -427 0 0 -701 1186 0c72,0 132,-11 179,-33 60,-28 90,-69 90,-124 0,-106 -90,-160 -269,-160l-1186 0 278 -356 868 0c214,0 389,40 522,122 150,92 225,225 225,396 0,97 -30,184 -90,262 -61,79 -144,139 -251,181l498 413zm711 0l257 -356 574 0 -356 -494 -620 850 -528 0 881 -1222c28,-41 66,-76 116,-107 55,-33 107,-49 158,-49 52,0 105,16 159,49 47,30 85,65 114,107l860 1222 -1615 0zm3438 -699c0,200 -78,367 -235,500 -157,133 -355,199 -594,199l-921 0 0 -858 427 0 0 504 494 0c118,0 215,-30 291,-91 77,-60 115,-139 115,-237 0,-98 -39,-179 -116,-241 -76,-63 -173,-95 -290,-95l-921 0 270 -356 651 0c242,0 441,62 596,186 156,125 233,288 233,489zm1583 345l-275 354 -1218 0 0 -1374 1493 0 -275 356 -791 0 0 145 999 0 -274 355 -725 0 0 164 1066 0z"
            />
            <polygon
              className="fil4"
              points="9707,7343 12540,7343 10823,4902 5400,4902 3641,7343 6456,7343 8089,5101 "
            />
            <path
              fill="currentColor"
              d="M30405 12367l0 -166 427 0 0 392c-42,41 -102,76 -181,107 -79,31 -159,46 -240,46 -102,0 -192,-22 -268,-65 -77,-43 -134,-105 -173,-186 -38,-80 -57,-168 -57,-262 0,-103 21,-194 64,-274 43,-80 106,-141 188,-184 64,-32 142,-49 236,-49 122,0 217,26 286,77 69,52 113,123 132,213l-197 37c-14,-48 -40,-86 -78,-114 -38,-28 -86,-42 -143,-42 -87,0 -156,27 -207,83 -51,55 -77,136 -77,245 0,117 26,205 78,263 52,58 120,88 204,88 41,0 83,-9 125,-25 42,-16 77,-36 107,-59l0 -125 -226 0zm596 -352l173 0 0 97c62,-75 136,-112 222,-112 45,0 85,9 118,28 34,19 61,47 83,85 31,-38 65,-66 101,-85 36,-19 75,-28 116,-28 52,0 97,10 133,32 36,21 63,52 81,93 13,31 20,80 20,148l0 455 -189 0 0 -407c0,-71 -6,-116 -19,-137 -17,-26 -45,-40 -81,-40 -26,0 -51,8 -74,24 -23,16 -40,40 -51,71 -10,31 -15,80 -15,147l0 342 -188 0 0 -390c0,-69 -4,-114 -10,-134 -7,-20 -17,-35 -32,-45 -13,-10 -33,-15 -57,-15 -29,0 -55,8 -78,24 -23,15 -40,38 -50,67 -10,30 -15,79 -15,147l0 346 -188 0 0 -713zm1227 713l0 -984 188 0 0 355c58,-66 127,-99 207,-99 86,0 158,31 215,94 57,63 85,154 85,272 0,121 -29,215 -87,281 -58,66 -128,99 -211,99 -41,0 -81,-11 -120,-31 -40,-21 -74,-52 -103,-92l0 105 -174 0zm185 -370c0,74 12,129 35,164 33,50 76,76 131,76 41,0 77,-18 106,-54 30,-36 44,-92 44,-169 0,-82 -15,-140 -44,-177 -30,-36 -67,-54 -114,-54 -45,0 -83,18 -113,53 -30,35 -45,89 -45,161zm664 370l0 -984 199 0 0 388 387 0 0 -388 200 0 0 984 -200 0 0 -430 -387 0 0 430 -199 0z"
            />
          </g>
        </g>
      </g>
    </chakra.svg>
  )
}

export default Logo
